import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ROUTES from '../../../../constants/routes';
import SearchInput from '../../../input/SearchInput';
import Button from '../../../button';

import plusIcon from '../../../../assets/plus.svg';
import composeEmailIcon from '../../../../assets/composeEmail.svg';
import { useSelector } from 'react-redux';

import { GROUPS } from '../../../../constants/roles';

const DashBoardTab = () => {
  const { userDetails } = useSelector((state) => state?.userDetails);
  const navigation = useNavigate();

  const [searchText, setSearchText] = useState('');

  const handleChangeSearchText = (e) => {
    setSearchText(e?.target?.value);
  };

  return (
    <div className='flex flex-col h-full gap-y-2 '>
      {/* <SearchInput
        placeholder={'Search'}
        handlechangesearch={handleChangeSearchText}
        value={searchText}
      /> */}
      <div className='mt-2'>
        <Button
          icon={plusIcon}
          title={'New Order'}
          iconStyle={'mr-2'}
          textStyle={`text-sm`}
          buttonStyle={'rounded-lg bg-transparent'}
          handleClick={() => {
            navigation(ROUTES.createOrder);
          }}
        />
      </div>

      {GROUPS.ADMINACCESSGROUP.includes(userDetails?.user?.role?.label) && (
        <>
          <Button
            icon={plusIcon}
            title={'New Partner'}
            iconStyle={'mr-2'}
            textStyle={`text-sm`}
            buttonStyle={'rounded-lg bg-transparent'}
            handleClick={() => {
              navigation(ROUTES.newProfile);
            }}
          />

          <button className={'flex p-2 rounded cursor-pointer items-center'}>
            <img src={composeEmailIcon} alt='composeIcon' className='mr-2' />
            <span className='text-white select-none text-sm'>
              Compose Email
            </span>
          </button>
        </>
      )}

      <div className='mt-2'>
        <Button
          // icon={plusIcon}
          title={'Test Map'}
          iconStyle={'mr-2'}
          textStyle={`text-sm`}
          buttonStyle={'rounded-lg bg-transparent'}
          handleClick={() => {
            navigation('testPage');
          }}
        />
      </div>
    </div>
  );
};

export default DashBoardTab;
