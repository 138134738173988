import React from 'react';

import './App.css';
import { Route, Routes } from 'react-router-dom';
import useAuthErrorHandler from './components/auth/useAuthErrorHandler';
import { useSelector } from 'react-redux';
import ROUTES from './constants/routes';

// Components
import Auth from './components/auth';
import Wrapper from './components/wrapper';
import SetPassword from './pages/setPassword';
import { GROUPS } from './constants/roles';

const Login = React.lazy(() => import('./pages/login'));
const ResetPassword = React.lazy(() => import('./pages/resetPassword'));
const Onboarding = React.lazy(() => import('./pages/onboarding'));

const Dashboard = React.lazy(() => import('./pages/dashboard'));
const CreateOrder = React.lazy(() => import('./pages/createOrder'));
const OrderDetails = React.lazy(() => import('./pages/orderDetails'));
const NewProfile = React.lazy(() => import('./pages/clientProfile/NewProfile'));
const Profile = React.lazy(() => import('./pages/clientProfile'));
const AllOrders = React.lazy(() => import('./pages/allOrders'));
const TestPage = React.lazy(() => import('./pages/testPage'));
const NotFound = React.lazy(() => import('./pages/notFound'));

const App = () => {
  const { userDetails } = useSelector((state) => state?.userDetails);

  useAuthErrorHandler();

  return (
    <Auth>
      {Object.entries(userDetails)?.length ? (
        <Wrapper>
          <Routes>
            {GROUPS.ADMINACCESSGROUP.includes(
              userDetails?.user?.role?.label
            ) && (
              <>
                <Route path='/' element={<Dashboard />} />

                <Route path={ROUTES.newProfile} element={<NewProfile />} />

                <Route
                  path={`${ROUTES.profile}/:profileID`}
                  element={<Profile />}
                />

                <Route path={`${ROUTES.allOrders}`} element={<AllOrders />} />
                <Route
                  path={`${ROUTES.allOrders}/:goToSearch`}
                  element={<AllOrders />}
                />
              </>
            )}

            <Route path={ROUTES.createOrder} element={<CreateOrder />} />

            <Route
              path={`${ROUTES.orderDetails}/:orderID`}
              element={<OrderDetails />}
            />

            <Route
              path={`${ROUTES.orderDetails}/:sectionName/:orderID`}
              element={<OrderDetails />}
            />

            <Route path={'/testPage'} element={<TestPage />} />

            <Route path={'*'} exact={true} element={<NotFound />} />
          </Routes>
        </Wrapper>
      ) : (
        <Routes>
          <Route path={ROUTES.login} element={<Login />} />
          <Route path={`${ROUTES.login}/:link`} element={<Login />} />
          <Route path={ROUTES.resetPassword} element={<ResetPassword />} />
          <Route
            path={`${ROUTES.setPassword}/:uniqueID`}
            element={<SetPassword />}
          />

          <Route
            path={`${ROUTES.onboarding}/:invitationID`}
            element={<Onboarding />}
          />
        </Routes>
      )}
    </Auth>
  );
};

export default App;
